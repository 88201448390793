<template>
    <div class="job-menagement">
        <div class="title">
            <div class="sub-title">职位管理</div>
        </div>
        <el-card class="box-card" style="padding-bottom: 20px; min-height:100%">
            <div class="tab-content flex">
                <div class="t-hx-tab-con flex">
                    <div class="t-hx-tab-item" :class="[tabIndex===0?'active':'']" @click="changeSearch(0)">所有职位 <span>{{ queryCount.allNum }}</span></div>
                    <div class="t-hx-tab-item" :class="[tabIndex===1?'active':'']" @click="changeSearch(1)">招聘中 <span>{{ queryCount.actionNum }}</span></div>
                    <div class="t-hx-tab-item" :class="[tabIndex===2?'active':'']" @click="changeSearch(2)">暂停招聘 <span>{{ queryCount.notActionNum }}</span></div>
                    <div class="t-hx-tab-item" :class="[tabIndex===3?'active':'']" @click="changeSearch(3)">违规职位 <span>{{ queryCount.status }}</span></div>
                </div>
                <div class="open-job active" @click="routeToPostJobNull"><i class="el-icon-plus"></i> 发布职位</div>
            </div>
            <div class="table-data">
                <div class="have-data" v-if="jobList.length>0">
                    <el-table
                        ref="multipleTable"
                        :data="jobList"
                        tooltip-effect="dark"
                        v-loading="loading"
                        stripe
                        style="width: 100%"
                        @selection-change="handleSelectionChange">
                        <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column>
                        <el-table-column
                            label="职位名称"
                            align="center"
                            prop="workName">
                        </el-table-column>
                        <el-table-column
                            label="发布时间"
                            align="center">
                            <template slot-scope="scope">
                                <span>{{scope.row.updateTime.split(' ')[0]}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            label="薪资标准">
                            <template slot-scope="scope">
                                <div class="sales">
                                    {{wagesList[scope.row.wagesId].desc}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            label="招聘人数"
                            prop="personNumber">
                        </el-table-column>
                        <el-table-column
                            align="center"
                            label="职位性质"
                            prop="workType">
                            <template slot-scope="scope">
                                <el-tag type="success" v-if="scope.row.workType==1" size="mini">全职</el-tag>
                                <el-tag type="primary" v-if="scope.row.workType==2" size="mini">灵活用工岗位</el-tag>
                                <el-tag type="primary" v-if="scope.row.workType==5" size="mini">共享用工</el-tag>
                                <el-tag type="warning" v-if="scope.row.workType==3" size="mini">不限</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            label="工作年限"
                            prop="workYears">
                        </el-table-column>
                        <el-table-column
                            align="center"
                            label="招聘状态">
                            <template slot-scope="scope">
                                <!-- <span>{{scope.row.isAction?'招聘中':'暂停招聘'}}</span> -->
                                <span v-if="scope.row.isAction" class="job-success">招聘中</span>
                                <span v-else-if="scope.row.status" class="job-danger">违规</span>
                                <span v-else class="job-danger">暂停招聘</span>
                                <!-- <span v-if="scope.row.state" class="job-danger">违规中</span> -->
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="违规原因"
                            align="center"
                            width="260px"
                            v-if="tabIndex===3"
                            prop="reason">
                            <template slot-scope="scope">
                                <span class="fail-reason fail-danger">{{scope.row.reason}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            label="操作" width="300px">
                            <template slot-scope="scope">
                                <div class="edit">
                                    <!-- <el-button size="small" type="success" :disabled="scope.row.status===1 || !scope.row.isAction" @click="routeToPostJob(scope.row.id)">修改</el-button> -->
                                    <template v-if="!scope.row.status">
                                        <el-button size="small" type="primary" :disabled="scope.row.status===1 || !scope.row.isAction" @click="routeToPostJob(scope.row.id)">修改</el-button>
                                        <el-button size="small" type="warning" v-if="!scope.row.isAction" @click="resetOneJobStatus(1, scope.row)">打开招聘</el-button>
                                        <el-button size="small" type="warning" v-if="scope.row.isAction" @click="resetOneJobStatus(2, scope.row)">暂停招聘</el-button>
                                    </template>
                                    <!-- <el-button size="small" type="warning" v-if="!scope.row.isAction" @click="resetOneJobStatus(1, scope.row)">打开招聘</el-button>
                                    <el-button size="small" type="warning" v-if="scope.row.isAction" @click="resetOneJobStatus(2, scope.row)">暂停招聘</el-button> -->
                                    <el-button size="small" type="danger" @click="deleteJob(scope.row)">删除</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="operation" v-if="tabIndex!=3">
                        <el-button size="small" type="success" :disabled="tabIndex===1" @click="resetJobStatus(1)">重新招聘</el-button>
                        <el-button size="small" type="warning" :disabled="tabIndex===2" @click="resetJobStatus(2)">暂停招聘</el-button>
                    </div>
                    <div>
                        <app-pagination @handleSizeChange="handleSize" @handleCurrentChange="handleCurrent" :total="total" :pageShow="pageShow" :current="current"></app-pagination>
                    </div>
                </div>
                <div class="no-data flex" v-else>
                    <img src="../../../assets/img/company/empty.png" alt="" srcset="">
                    <p class="no-tips">亲爱的用户，目前您还没有发布相关职位~~</p>
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
import companyRequest from '../../../api/company'
import AppPagination from '../../../components/pagination/AppPagination'
export default {
    components: {
        AppPagination
    },
    mounted() {
        this.queryJobs()
        this.queryNums()
    },
    data() {
        return {
            loading: true,
            tabIndex: 0,
            jobList: [], //职位列表
            pageShow: true,
            queryForm: { //查询条件
                pageNum: 1,
                pageSize: 10,
                status: 0, // 0未违规 1违规
                isAction: null // 0暂停 1招聘中
            },
            current: 1,
            multipleSelection: [], //选择项目
            total: 0,
            queryCount: {
                actionNum: 0, //招聘中
                allNum: 0, //所有职位
                notActionNum: 0, //暂停
                status: 0 //违规
            },
            wagesList: [ //薪资标准
                {id: 0, desc: '面议'},
                {id: 1, desc: '1000-2000元'},
                {id: 2, desc: '2000-3000元'},
                {id: 3, desc: '3000-5000元'},
                {id: 4, desc: '5000-8000元'},
                {id: 5, desc: '8000-12000元'},
                {id: 6, desc: '12000-15000元'},
                {id: 7, desc: '15000-20000元'},
                {id: 8, desc: '20000元以上'},
            ],  
        }
    },
    methods: {
        handleCurrent(num) {
            this.queryForm.pageNum = num
            // console.log('num', this.queryForm.pageNum)
            this.queryJobs()
        },
        handleSize(size) {
            this.queryForm.pageSize = size
            this.queryForm.pageNum = 1
            // console.log('size', this.queryForm.pageSize)
            this.queryJobs()
        },
        //删除职位
        deleteJob(row) {
            this.$confirm('此操作将永久删除该职位, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.queryForm.pageNum = 1
                this.current = 1
                this.pageShow = false
                companyRequest.deleteJob({workId: row.id}).then((res) => {
                    if (res.code === 200) {
                        this.$message.success('删除成功')
                        this.queryJobs()
                        this.queryNums()
                    }
                })
                // this.$message({
                //     type: 'success',
                //     message: '删除成功!'
                // })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })         
            })
        },
        //职位重新招聘和暂停
        resetJobStatus(flag) {
            if (this.multipleSelection.length === 0) {
                this.$message.warning('请先选择职位')
                return
            }
            let isAction = null
            if (flag ===1) { //重新招聘职位
                isAction = true
            } else if (flag ===2) { //暂停职位
                isAction = false
            }
            this.current = 1
            this.pageShow = false
            companyRequest.changeActionWork({workIds: this.multipleSelection, isAction: isAction}).then((res) => {
                if (res.code === 200) {
                    this.$message.success('操作成功')
                    this.queryForm.pageNum = 1
                    this.queryJobs()
                    this.queryNums()
                }
            }) 
        },
        resetOneJobStatus(flag, row) {
            // console.log('flag', flag)
            // console.log('row', row)
            let isAction = null
            if (flag ===1) { //重新招聘职位
                isAction = true
            } else if (flag ===2) { //暂停职位
                isAction = false
            }
            this.queryForm.pageNum = 1
            this.current = 1
            this.pageShow = false
            companyRequest.changeActionWork({workIds: [row.id], isAction: isAction}).then((res) => {
                if (res.code === 200) {
                    this.$message.success('操作成功')
                    this.pageShow = true
                    this.queryJobs()
                    this.queryNums()
                }
            }) 
        },
        changeSearch(tabIndex) {
            this.loading = true
            this.current = 1
            this.pageShow = false
            this.tabIndex = tabIndex
            if (tabIndex === 0) { //所有职位
                this.queryForm.status = 0
                this.queryForm.isAction = null
            } else if (tabIndex === 1) { //招聘中
                this.queryForm.status = 0
                this.queryForm.isAction = 1
            } else if (tabIndex === 2) { //暂停招聘
                this.queryForm.status = 0
                this.queryForm.isAction = 0
            } else if (tabIndex === 3) { //违规职位
                this.queryForm.status = 1
                this.queryForm.isAction = null
            }
            this.queryForm.pageNum = 1
            // this.queryForm.pageSize = 10
            //获取职位
            this.queryJobs()
        },
        //跳转到职位发布页面
        routeToPostJob(id) {
            // console.log('id', id)
            this.$router.push({
                path: '/company/postJob',
                query: {
                    id: id
                }
            })
        },
        handleSelectionChange(val) {
            // let status = null
            //清空
            const statusList = []
            // console.log('val', val)
            val.forEach((item) => {
                statusList.push(item.id)
            })
            this.multipleSelection = statusList
            // if (val.length === 0) {
            //     this.multipleSelection = []
            // } else {
            //     val.forEach((item, index) => {
            //         status = this.multipleSelection.includes(item.id)
            //         console.log('status', status)
            //         if(!status) {
            //             this.multipleSelection.push(item.id)
            //             console.log('不存在')
            //         } else {
            //             this.multipleSelection = this.multipleSelection.filter((row) => {
            //                 return row.id !== item.id
            //                 console.log('存在')
            //             })
            //         }
            //     })  
            // }
            // console.log('multipleSelection', this.multipleSelection)
        },
        //查询职位
        queryJobs() {
            companyRequest.queryJobs(this.queryForm).then((res) => {
                if (res.code === 200) {
                    this.loading = false
                    this.pageShow = true
                    this.jobList = res.data.list
                    this.total = res.data.total
                    // this.pageShow = true
                }
            }).catch((err) => {
                 this.jobList = []
            })
        },
        //获取数字统计
        queryNums() {
            companyRequest.queryNums().then((res) => {
                if (res.code === 200) {
                    this.queryCount.actionNum = res.data.actionNum
                    this.queryCount.allNum = res.data.allNum
                    this.queryCount.notActionNum = res.data.notActionNum
                    this.queryCount.status = res.data.status
                }
            })
        },
        routeToPostJobNull() {
            this.$router.push({
                path: '/company/postJob'
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
.job-menagement{
    height: 100%;
    .tab-content{
        justify-content: space-between;
    }
    .table-data{
        margin-top: 25px;
    }
    .t-hx-tab-con{
        .t-hx-tab-item{
            height: 52px;
            line-height: 52px;
            width: 180px;
            text-align: center;
            cursor: pointer;
            font-size: 14px;
            color: #9fa4b0;
            background-color: #fff;
            border-top: 2px solid transparent;
            border-bottom: 2px solid #f5f5fa;
            border-right: 2px solid #f5f5fa;
            span{
                display: inline-block;
                border-radius: 100%;
                font-size: 14px;
                width: 20px;
                height: 20px;
                line-height: 20px;
                text-align: center;
                background-color: #ffe7e1;
                border-color: #ffe7e1;
                color: #ff704f;
            }
            &.active{
                border-top-color: #ff704f;
                border-bottom-color: #fff;
                font-size: 16px;
                color: #111;
                span{
                    background: #ff704f;
                    color: #fff;
                }
            }
        }
    }
    .operation{
        padding: 15px 0 15px 30px;
        background: #fff8f6;
    }
    .open-job{
        cursor: pointer;
        height: 52px;
        line-height: 52px;
        padding: 0 20px;
        .el-icon-plus{
            font-weight: 700;
        }
    }
    .table-data{
       .no-data{
           margin-top: 180px;
           flex-direction: column;
           justify-content: center;
           align-items: center;
           .tips{
               margin-top: 20px;
               color: #bbb;
           }
       } 
    }
    .job-success{
        color: $success;
        font-weight: 550;
    }
    .job-danger{
        color: #f56c6c;
        font-weight: 550;
    }
    .fail-danger{
        color: #f56c6c;
        font-weight: 550;
    }
    .fail-reason{
        // width: 100%;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
    }
}
</style>